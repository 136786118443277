/* eslint-disable jsx-a11y/label-has-associated-control */
import { Select } from 'antd'
import Button from 'components/button'
import Loading from 'components/loading'
import { useFormik } from 'formik'
import { find, map } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBolt } from 'react-icons/fa'
import { useMutation, useQueries, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { useQuoteLayout } from '../QuoteLayout'
import {
    addMoreNovoConfigurationOnQuote,
    getAllNovoConfigurationRemaining,
    getAllRemainingStock,
} from '../queries/quotes'

const size = 'large'
const roundedClass = 'rounded-lg flex-1'

const AddNovoConfiguration = ({ callback }: { callback: () => void }) => {
    const { quoteDetail } = useQuoteLayout()
    const { t } = useTranslation()
    const { isLoading, data } = useQuery(
        ['getNovoConfigurationList'],
        getAllNovoConfigurationRemaining,
    )
    const [stockList, setStockList] = useState<any>([])

    const addNovoConfiguration = useMutation(
        ['addMoreConfigurationOnQuote'],
        (params: { novoConfigurationsId: number[]; stockId: number[] }) =>
            addMoreNovoConfigurationOnQuote(
                {
                    configurations: params.novoConfigurationsId,
                    stocks: params.stockId,
                },
                quoteDetail.id,
            ),
        {
            onSuccess: res => {
                toast(res?.data?.message || 'Configuration added', successToast)
                callback()
            },
            onError: (error: any) => {
                toast(error?.message || 'Failed to add configuration', errorToast)
            },
        },
    )

    const getConfiguration = (item: number) => {
        const config: any = find(data?.data?.data, { value: item })
        return (
            config || {
                label: '',
            }
        )
    }

    const getStock = (item: number) => {
        const stock: any = find(stockList, { value: item })
        return (
            stock || {
                label: '',
            }
        )
    }

    const stockInfos = useQueries([
        {
            queryKey: ['stockList'],
            queryFn: () => getAllRemainingStock(),
            onSuccess: (d: any) => {
                if (d?.data.data.length === 0) {
                    toast(t('message.no_stock_found_for_the_user'), { type: 'error' })
                }
                setStockList(d?.data.data || [])
            },
            retryOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        },
    ])

    const formik = useFormik({
        initialValues: {
            quote_id: quoteDetail.id,
            quotation_name: quoteDetail.quotation_name,
            quotation_for: quoteDetail.quotation_for,
            status: quoteDetail.status,
            configurations: [],
            stocks: [],
            template: '',
        },
        onSubmit: async values => {
            const configurations = map(values.configurations, item => {
                return getConfiguration(item)?.value
            })
            const stocks = map(values.stocks, item => {
                return getStock(item)?.value
            })
            await addNovoConfiguration.mutate({
                novoConfigurationsId: configurations,
                stockId: stocks,
            })
        },
    })

    if (isLoading) {
        return (
            <div className="w-full h-full grid place-content-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <label>{t('api.select_novo_configuration')}</label>
                <Select
                    mode="multiple"
                    size={size}
                    allowClear
                    showSearch
                    className={roundedClass}
                    placeholder="Select configuration"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => formik.setFieldValue('configurations', value, true)}
                >
                    {map(data?.data?.data || [], (option: any) => (
                        <Select.Option value={option.value} key={option.value}>
                            <div className="flex items-center gap-4">
                                {option.is_electric && (
                                    <div
                                        title="This configuration is based on Electric"
                                        className="text-yellow-400 cursor-pointer"
                                    >
                                        <FaBolt size={15} />
                                    </div>
                                )}
                                <p className="truncate max-w-sm">
                                    {option.label} ({option.part_number})
                                </p>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
                {formik.errors && formik.errors.configurations && formik.touched.configurations && (
                    <p className="text-red-600 mt-1">{formik.errors.configurations}</p>
                )}
            </div>
            <div className="flex flex-col gap-2">
                <label>{t('api.select_stocks')}</label>
                <Select
                    mode="multiple"
                    size={size}
                    allowClear
                    showSearch
                    className={roundedClass}
                    placeholder="Select configuration"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => formik.setFieldValue('stocks', value, true)}
                >
                    {map(stockList || [], (option: any) => (
                        <Select.Option value={option.value} key={option.value}>
                            <div className="flex items-center gap-4">
                                {option.is_electric && (
                                    <div
                                        title="This configuration is based on Electric"
                                        className="text-yellow-400 cursor-pointer"
                                    >
                                        <FaBolt size={15} />
                                    </div>
                                )}
                                <p className="truncate">
                                    {option.label} ({option.part_number}) ({t('api.quantity')}
                                    {option.stock_quantity} - {t('api.reserved')}{' '}
                                    {option.reserved_quantity})
                                </p>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
                {formik.errors && formik.errors.configurations && formik.touched.configurations && (
                    <p className="text-red-600 mt-1">{formik.errors.configurations}</p>
                )}
            </div>
            <div className="border border-slate-300 rounded-md">
                <p className="py-2 px-4 text-xl">{t('api.overview')}</p>
                <hr />
                <div className="py-2 px-4">
                    <table className="table-auto w-[100%]">
                        <tbody>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.quote_name')}
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {quoteDetail?.quotation_name || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.quote_for')}
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {quoteDetail?.quotation_for || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.total_configuration_list')}
                                </th>
                                <td className="py-2 font-bold text-md text-gray-900 whitespace-nowrap text-right">
                                    {formik.values.configurations.length}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.selected_configuration')}:
                                </th>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    className="py-2 w-full text-md text-gray-900 whitespace-nowrap flex flex-col"
                                >
                                    <div className="w-full max-w-xl">
                                        {map(
                                            formik.values.configurations,
                                            (item: any, index: number) => {
                                                return (
                                                    <p
                                                        className="truncate font-bold "
                                                        key={getConfiguration(item)?.id}
                                                        title={`${
                                                            getConfiguration(item).novo_quote_number
                                                        } ${getConfiguration(item).label}`}
                                                    >
                                                        {index + 1}.{' '}
                                                        {getConfiguration(item).novo_quote_number}-
                                                        {getConfiguration(item).label}
                                                    </p>
                                                )
                                            },
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                    {t('api.selected_stocks')}:
                                </th>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    className="py-2 w-full text-md text-gray-900 whitespace-nowrap flex flex-col"
                                >
                                    <div className="w-full">
                                        {map(formik.values.stocks, (item: any, index: number) => {
                                            return (
                                                <p
                                                    className="truncate font-bold "
                                                    key={getStock(item)?.id}
                                                    title={`${getStock(item).novo_quote_number} ${
                                                        getStock(item).label
                                                    }`}
                                                >
                                                    {index + 1}. {getStock(item).novo_quote_number}-
                                                    {getStock(item).label}{' '}
                                                    {`${t('api.quantity')} ${
                                                        getStock(item).stock_quantity
                                                    } - ${t('api.reserved')} ${
                                                        getStock(item).reserved_quantity
                                                    }`}
                                                </p>
                                            )
                                        })}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex justify-end">
                <div className="w-fit">
                    <Button label="Confirm Add" onClick={() => formik.handleSubmit()} />
                </div>
            </div>
        </div>
    )
}

export default AddNovoConfiguration
