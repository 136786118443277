import Button from 'components/button'
import Loading from 'components/loading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Modal } from 'react-responsive-modal'
import { toast } from 'react-toastify'
import { getStockDetailById, revokeStock } from '../query'

interface StockQuantityViewProps {
    stockQuantity: number
    stockId: number
    reservedQuantity: number
    soldQuantity: number
    novoQuoteNumber: string
    description: string
}

const StockQuantityView = (props: StockQuantityViewProps) => {
    const { stockQuantity, stockId, reservedQuantity, soldQuantity, novoQuoteNumber, description } =
        props
    const { t } = useTranslation()
    const [showQuantityModal, setShowQuantityModal] = useState<boolean>(false)

    const { data, isLoading, isRefetching, refetch } = useQuery(
        ['stock.get_stock_detail', stockId],
        () => getStockDetailById(stockId),
        {
            enabled: showQuantityModal,
            onError: (error: Error) => {
                toast.error(error?.message || t('api.get_stock_detail_error'))
            },
        },
    )

    const handleRevoke = useMutation(
        ['stock.revoke_stock'],
        (params: { stockId: number; configurationId: number }) =>
            revokeStock(stockId, {
                configuration_id: params.configurationId,
            }),
        {
            onSuccess: () => {
                toast.success(t('api.stock_revoke_success'))
                refetch()
            },
            onError: (error: any) => {
                toast.error(error?.message || t('api.stock_revoke_error'))
            },
        },
    )

    return (
        <>
            <div
                className="cursor-pointer"
                onClick={() => {
                    setShowQuantityModal(true)
                }}
            >
                {stockQuantity}
            </div>
            <Modal
                open={showQuantityModal}
                onClose={() => {
                    setShowQuantityModal(false)
                }}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[800px]',
                }}
                showCloseIcon={false}
                center
            >
                <div className="flex flex-col gap-4">
                    <p className="font-bold">{t('api.stock_detail')}</p>
                    <div className="flex gap-2 items-end flex-col">
                        <p>
                            {t('api.novo_quote_number')} : {novoQuoteNumber}
                        </p>
                        <p className="w-full text-slate-500">{description}</p>
                    </div>
                    <div className="flex gap-4 justify-between">
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.stock_qty')}</p>
                            <p className="text-xl">{stockQuantity}</p>
                        </div>
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.reserved_qty')}</p>
                            <p className="text-xl">{reservedQuantity}</p>
                        </div>
                        <div className="flex flex-1 rounded-md border-2 border-slate-200 p-2 flex-col gap-1">
                            <p className="text-sm text-slate-500">{t('api.sold_qty')}</p>
                            <p className="text-xl">{soldQuantity}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="w-full flex gap-4">
                        <p className="w-80">{t('api.name')}</p>
                        <p className="w-44">{t('api.quote_for')}</p>
                        <p className="w-24">{t('api.qty')}</p>
                        <p className="w-32 flex justify-end">{t('api.actions')}</p>
                    </div>
                    <div className="flex flex-col gap-4 pr-1 overflow-auto max-h-96">
                        {(isLoading || isRefetching) && <Loading />}
                        {data?.data.data.map((item: any) => (
                            <div
                                key={item.id}
                                className="w-full py-1 border-slate-100 border-b-2 items-center flex gap-4"
                            >
                                <div className="flex flex-col justify-end w-80">
                                    <p>{item.created_by.name}</p>
                                    <p className="text-sm text-slate-500">
                                        {item.created_by.email}
                                    </p>
                                </div>
                                <p className="w-44">{item?.quote?.quotation_for}</p>
                                <p className="w-12 text-center">{item.quantity}</p>
                                <div className="flex-1 flex justify-end">
                                    {item?.configuration_price?.status === 8 ? (
                                        <p className="text-red-300">{t('api.revoked')}</p>
                                    ) : item?.quote?.status === 'pending order' ? (
                                        <p className="text-primary">{t('api.send_to_order')}</p>
                                    ) : (
                                        <div className="w-fit">
                                            <Button
                                                variant="outline"
                                                onClick={() => {
                                                    handleRevoke.mutate({
                                                        stockId,
                                                        configurationId: item.id,
                                                    })
                                                }}
                                                label={t('api.revoke')}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default StockQuantityView
